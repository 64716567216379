$(document).on("turbolinks:load", () => {
  if ( $("#sale_events.edit, #sale_events.new").length > 0 ) {
    setSalePricePerRevUnit();
    setAdjustedPrice();
    loopSensitivities();
    setCapRate();
    setSummaryEgim();
    loopRevUnitOccupancy();
    setSummaryOccupancy();
    loopAdjustedSalePrice();
  }
})

function loopSensitivities() {
  loopNoi();
  loopEgim();
  loopExpenseRatio();
  loopNoiPerUnit();
  loopInferredCapRate();
}

////////////////////
//
// Helper functions
//
////////////////////

function getNumberFromCurrency(currency) {
  return Number(currency.replace(/[$,]/g,''))
}

function getCurrencyFromNumber(number) {
  var output = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  });

  return output.format(number)
}

function getColumn(item) {
  var period = $(item).data('period')
  return $("input[data-period='" + period + "']");
}

function getFinancialPeriod() {
  var selectedOption = $("#sale_event_financial_period_id").children("option:selected");
  return selectedOption.text().toLowerCase().replace(/[ ]/g,'-');
};

var care_type_default_fields = "#care-types-table tr.row-il input[id$=_units], #care-types-table tr.row-al input[id$=_units], #care-types-table tr.row-mc input[id$=_units], #care-types-table tr.row-aa input[id$=_units], #care-types-table tr.row-nc input[id$=_beds]"

$(document).on('change', care_type_default_fields, function(e) {
  $this = $(this);
  value = $this.val();
  $rev_units = $this.closest('tr').find('input[id$=_revenue_units]');
  $rev_units.val(value);

  $(this).trigger('pricePerRevUnit:calculate');
  $(this).trigger('summaryOccupancy:calculate');
});

function get_total_rev_units() {
  var total_output = 0;
  var $unit_fields = $("#care-types-table tr input[id*='_revenue_units']");
  $unit_fields.each(function() {
    the_val = parseInt($(this).val()) || 0;
    total_output += the_val
  })
  return total_output;
};

var get_sale_price_per_rev_unit;
get_sale_price_per_rev_unit = function(total_rev_units) {
  var total_output = 0;
  var sale_price = getNumberFromCurrency($("#sale_event_sale_price").val());

  total_output = sale_price / total_rev_units
  total_output = getCurrencyFromNumber(total_output)

  return total_output;
};

function setSalePricePerRevUnit() {
  var $per_rev_unit_input = $("#sale_event_sale_price_per_rev_unit");
  var total_rev_units = get_total_rev_units();

  price = get_sale_price_per_rev_unit(total_rev_units);

  if (total_rev_units > 0 && price != '$0') {
    $per_rev_unit_input.val(price);
  } else {
    $per_rev_unit_input.val('');
  }
}

function setAdjustedPrice() {
  var adjustedPrice, adjustedPriceInput, adjustedPriceOutput, capitalAdjustment, deferredMaintenance, salePrice;

  $adjustedPriceInput = $("#sale_event_adjusted_price");
  salePrice = getNumberFromCurrency($("#sale_event_sale_price").val());
  deferredMaintenance = getNumberFromCurrency($("#sale_event_deferred_maintenance").val());
  capitalAdjustment = getNumberFromCurrency($("#sale_event_capital_adjustment").val());

  adjustedPrice = salePrice + deferredMaintenance + capitalAdjustment;
  adjustedPriceOutput = getCurrencyFromNumber(adjustedPrice)

  if (adjustedPriceOutput != '$0') {
    $adjustedPriceInput.val(adjustedPriceOutput);
  } else {
    $adjustedPriceInput.val('');
  }
}

function loopRevUnitOccupancy() {
  var $occ_fields, total_output, tmpVal, occValue, unitValue, $unitInput;
  total_output = 0;

  $occ_fields = $("#care-types-table tr input[id$='_occupancy']");
  $occ_fields.each(function() {
    total_output += setRevUnitOccupancy(this)
  })
  return total_output;
};

function setSummaryOccupancy() {
  var totalRevUnits, revUnitOccupancy, occupancyOutput, $occupancyInput;
  $occupancyInput = $("#sale_event_occupancy_at_sale")
  totalRevUnits = get_total_rev_units();
  revUnitOccupancy = loopRevUnitOccupancy();

  occupancyOutput = ((revUnitOccupancy / totalRevUnits) * 100).toFixed(2) + '%';
  $occupancyInput.val(occupancyOutput);
}

function setRevUnitOccupancy(item) {
  var $occ_fields, total_output, tmpVal, occValue, unitValue, $unitInput;
  $this = $(item)
  $unitInput = $this.closest('tr').find("input[id$='_revenue_units']");
  occValue = parseFloat($this.val()) || 0;
  unitValue = parseInt($unitInput.val()) || 0;

  return (occValue / 100) * unitValue;
};

function setCapRate() {
  var $capRate, $column, $ircInput, ircValue, period;
  $capRate = $("#sale_event_capitalization_rate");
  period = getFinancialPeriod();
  $column = $("input[data-period='" + period + "']");
  $ircInput = $column.filter("input[data-variable='inferred-capitalization-rate']");
  ircValue = $ircInput.val();
  $capRate.val(ircValue)
};

function setSummaryEgim() {
  var $summaryEgim, $column, $egimInput, egimValue, period;
  $summaryEgim = $("#sale_event_egim");
  period = getFinancialPeriod();
  $column = $("input[data-period='" + period + "']");
  $egimInput = $column.filter("input[data-variable='effective-gross-income-multiplier']");
  egimValue = $egimInput.val();
  $summaryEgim.val(egimValue)
};

////////////////////
//
// NOI calculations
//
////////////////////

function loopNoi() {
  var $noiInputs = $("input[data-variable='net-operating-income']");

  $noiInputs.each(function() {
    setNoi(this);
  })
}

function setNoi(item) {
  var $egiInput, $oexInput, noiOutput, egiValue, $column;

  $this = $(item)
  $column = getColumn(item);
  $egiInput = $column.filter("input[data-variable='effective-gross-income']");
  $oexInput = $column.filter("input[data-variable='operating-expenses']");

  egiValue = getNumberFromCurrency($egiInput.val());
  oexValue = getNumberFromCurrency($oexInput.val());

  if (egiValue > 0 && oexValue > 0) {
    noiOutput = egiValue - oexValue;

    $this.val(getCurrencyFromNumber(noiOutput));
  }
}

////////////////////
//
// NOI Per Unit calculations
// noi per uni = noi / total rev units
//
////////////////////

function loopNoiPerUnit() {
  var $noiInputs = $("input[data-variable='noi-per-rev-unit']");

  $noiInputs.each(function() {
    setNoiPerUnit(this);
  })
}

function setNoiPerUnit(item) {
  var $column, $noiInput, $this, noiOutput, noiValue, total_rev_units;

  $this = $(item)
  $column = getColumn(item);
  $noiInput = $column.filter("input[data-variable='net-operating-income']");
  total_rev_units = get_total_rev_units();
  noiValue = getNumberFromCurrency($noiInput.val());

  if (total_rev_units > 0 && noiValue > 0) {
    noiOutput = noiValue / total_rev_units;

    $this.val(getCurrencyFromNumber(noiOutput));
  }
}

////////////////////
//
// EGIM calculations
// egim = sale_price/effective-gross-income
//
////////////////////

function loopEgim() {
  var $egimInputs = $("input[data-variable='effective-gross-income-multiplier']");

  $egimInputs.each(function() {
    setEgim(this);
  })
}

function setEgim(item) {
  var $column, $grossInput, $this, egimValue, grossValue, adjustedSalePrice;

  $this = $(item)
  adjustedSalePrice = getNumberFromCurrency($("#sale_event_adjusted_price").val());
  $column = getColumn(item);
  $grossInput = $column.filter("input[data-variable='effective-gross-income']");
  grossValue = getNumberFromCurrency($grossInput.val());

  if (adjustedSalePrice > 0 && grossValue > 0) {
    egimValue = (adjustedSalePrice / grossValue).toFixed(2)

    $this.val(egimValue);
  }
}

////////////////////
//
// Expense Ratio calculations
// expense ratio = (operating expenses / effective gross income) * 100
//
////////////////////

function loopExpenseRatio() {
  var $expenseRatioInputs = $("input[data-variable='expense-ratio']");

  $expenseRatioInputs.each(function() {
    setExpenseRatio(this);
  })
}

function setExpenseRatio(item) {
  var $column, expenseValue, $grossInput, grossValue, $opExInput, opExValue;
  $this = $(item)
  $column = getColumn(item);
  $opExInput = $column.filter("input[data-variable='operating-expenses']");
  $grossInput = $column.filter("input[data-variable='effective-gross-income']");
  opExValue = getNumberFromCurrency($opExInput.val());
  grossValue = getNumberFromCurrency($grossInput.val());

  if (opExValue > 0 && grossValue > 0) {
    expenseValue = ((opExValue / grossValue) * 100).toFixed(2) + '%'

    $this.val(expenseValue);
  }
}

////////////////////
//
// Inferred Cap Rate calculations
// inferred cap rate = noi / sale price
//
////////////////////

function loopInferredCapRate() {
  var $ircInputs = $("input[data-variable='inferred-capitalization-rate']");

  $ircInputs.each(function() {
    setInferredCapRate(this);
  })
}

function setInferredCapRate(item) {
  var $column, $noiInput, $this, icrOutput, noiValue, adjustedSalePrice;

  $this = $(item)
  $column = getColumn(item);
  $noiInput = $column.filter("input[data-variable='net-operating-income']");
  adjustedSalePrice = getNumberFromCurrency($("#sale_event_adjusted_price").val());
  noiValue = getNumberFromCurrency($noiInput.val());

  if (adjustedSalePrice > 0 && noiValue > 0) {
    icrOutput = ((noiValue / adjustedSalePrice) * 100).toFixed(2) + '%';

    $this.val(icrOutput);
  }
}

////////////////////
//
// Adjusted Sale Price calculations
// adjusted sale price = sale price + deferred maintenance + capital adjustment
//
////////////////////

function loopAdjustedSalePrice() {
  var $adjustedSalePriceInputs = $("input[data-variable='adjusted-sale-price']");
  $adjustedSalePriceInputs.each(function() {
    setAdjustedSalePrice(this);
  })
}

function setAdjustedSalePrice(item) {
  var $aspInput, $this, aspValue;

  $this = $(item)
  $aspInput = $("#sale_event_adjusted_price");
  aspValue = getNumberFromCurrency($aspInput.val());

  if (aspValue > 0) {
    $this.val(getCurrencyFromNumber(aspValue));
  }
}

////////////////////
//
// Triggers
//
////////////////////

$(document).on('change', '#sale_event_sale_price', function(e) {
  $(this).trigger('pricePerRevUnit:calculate');
  $(this).trigger('adjustedPrice:calculate');
  $(this).trigger('egim:calculate-all');
  $(this).trigger('summaryEgim:calculate');
  $(this).trigger('inferredCapRate:calculate-all');
  $(this).trigger('capRate:calculate');
  $(this).trigger('adjustedSalePrice:calculate-all');
});

$(document).on('change', '#sale_event_deferred_maintenance, #sale_event_capital_adjustment', function(e) {
  $(this).trigger('adjustedPrice:calculate');
  $(this).trigger('adjustedSalePrice:calculate-all');
  $(this).trigger('egim:calculate-all');
  $(this).trigger('inferredCapRate:calculate-all');
  $(this).trigger('capRate:calculate');
  $(this).trigger('summaryEgim:calculate');
});

$(document).on('change', "input[data-variable='operating-expenses']", function(e) {
  $(this).trigger('noi:calculate');
  $(this).trigger('noiPerUnit:calculate');
  $(this).trigger('expense:calculate');
  $(this).trigger('inferredCapRate:calculate');
  $(this).trigger('capRate:calculate');
});

$(document).on('change', "input[data-variable='effective-gross-income']", function(e) {
  $(this).trigger('noi:calculate');
  $(this).trigger('noiPerUnit:calculate');
  $(this).trigger('expense:calculate');
  $(this).trigger('inferredCapRate:calculate');
  $(this).trigger('capRate:calculate');
  $(this).trigger('egim:calculate');
  $(this).trigger('summaryEgim:calculate');
});

$(document).on('change', "#sale_event_financial_period_id", function(e) {
  $(this).trigger('capRate:calculate');
  $(this).trigger('summaryEgim:calculate');
});

$(document).on('change', "#care-types-table tr input[id$='_occupancy']", function(e) {
  $(this).trigger('summaryOccupancy:calculate');
});

$(document).on('change', "#care-types-table tr input[id$='_revenue_units']", function(e) {
  $(this).trigger('noiPerUnit:calculate-all');
});

////////////////////
//
// Recipients
//
////////////////////

$(document).on('pricePerRevUnit:calculate', function(e) {
  setSalePricePerRevUnit();
});

$(document).on('adjustedPrice:calculate', function(e) {
  setAdjustedPrice();
});

$(document).on('noi:calculate', function(e) {
  $column = getColumn(e.target);
  item = $column.filter("input[data-variable='net-operating-income']");
  setNoi(item);
});

$(document).on('egim:calculate', function(e) {
  $column = getColumn(e.target);
  item = $column.filter("input[data-variable='effective-gross-income-multiplier']");
  setEgim(item);
});

$(document).on('egim:calculate-all', function(e) {
  loopEgim();
});

$(document).on('expense:calculate', function(e) {
  $column = getColumn(e.target);
  item = $column.filter("input[data-variable='expense-ratio']");
  setExpenseRatio(item);
});

$(document).on('noiPerUnit:calculate', function(e) {
  $column = getColumn(e.target);
  item = $column.filter("input[data-variable='noi-per-rev-unit']");
  setNoiPerUnit(item);
});

$(document).on('inferredCapRate:calculate', function(e) {
  $column = getColumn(e.target);
  item = $column.filter("input[data-variable='inferred-capitalization-rate']");
  setInferredCapRate(item);
});

$(document).on('inferredCapRate:calculate-all', function(e) {
  loopInferredCapRate();
});

$(document).on('capRate:calculate', function(e) {
  setCapRate();
});

$(document).on('summaryEgim:calculate', function(e) {
  setSummaryEgim();
});

$(document).on('revUnitOccupancy:calculate', function(e) {
  loopRevUnitOccupancy();
});

$(document).on('summaryOccupancy:calculate', function(e) {
  setSummaryOccupancy();
});

$(document).on('noiPerUnit:calculate-all', function(e) {
  loopNoiPerUnit();
});

$(document).on('adjustedSalePrice:calculate-all', function(e) {
  loopAdjustedSalePrice();
});
