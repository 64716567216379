// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()
require('select2')
require('pc-bootstrap4-datetimepicker')
require("packs/surveys")
require("packs/maps")
require("packs/sales_table")
require("packs/calculated_fields")
require("libs/helpers")

window.Rails = Rails

import 'bootstrap'
import 'data-confirm-modal'
import 'select2'
import 'select2/dist/css/select2.css'
import 'select2-theme-bootstrap4/dist/select2-bootstrap.css'
import 'pc-bootstrap4-datetimepicker'
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css'
import 'baremetrics-calendar/public/css/application.css'

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $('.select2').select2({
    theme: 'bootstrap'
  })

  $('.datepicker').datetimepicker({
    format: 'YYYY/MM/DD'
    // format: 'MM/DD/YYYY'
  })

  var feeRows = $('#other-fees-table tr')
  feeRows.each(function() {
    var lastFrequency = $(this).find('.frequency-select').last();
    lastFrequency.removeClass('hidden').addClass('frequency-primary');
  });

  $('.frequency-primary').on('change', (s) => {
    let selectedFrequencyElement = $(s.target).first();
    let selectedFrequencyId = selectedFrequencyElement.val();
    let hiddenFrequencyFields = $(selectedFrequencyElement).first().closest('tr').find('.frequency-select.hidden');
    $(hiddenFrequencyFields).each(function() {
      $(this).val(selectedFrequencyId);
    });
  })

  $('form').on('click', '.delete-btn', function(event) {
    $(this).closest('tr').find('.destroy-field').val('1');
    hide_destroyed_instance($(this));
    return event.preventDefault();
  });

  $('form').on('click', '.add_fields, .add_fields_table', function(event) {
    event.preventDefault();
    var empty_table_row, regexp, time;
    empty_table_row = $(this).parent('div').find('table .empty-table');

    if (empty_table_row.length > 0) {
      empty_table_row.hide();
    }

    time = new Date().getTime();
    regexp = new RegExp($(this).data('id'), 'g');

    if ($(this).hasClass('add_fields_table')) {
      $(this).closest('.parent-group').find('table > tbody').append($(this).data('fields').replace(regexp, time));
      // handle_nested($(this));
    } else {
      $(this).before($(this).data('fields').replace(regexp, time));
    }

    if ($(this).text() == 'Add Unit Mix') {
      $('#unit-mix-table tr').last().find("select[id$='care_type_id']").val("").trigger("change");
    }

    return event.preventDefault();
  });

  // $('#care-types-table .level-select select').on("select2:select", function(e) {
  //    var levelText = $(this).select2('data');
  // });

  if ($('.pdf-hider-labels').length > 0) {
    $('.pdf-hider-labels').each(function() {
      return $(this).closest('.form-group').find('.pdf-hider-fields').hide();
    });
  }

  $('form').on('click', '.replace-pdf', function(e) {
    var pdfFields, pdfLabels;
    e.preventDefault();
    pdfLabels = $(this).closest('.pdf-hider-labels');
    pdfFields = $(this).closest('.form-group').find('.pdf-hider-fields');
    pdfLabels.hide();
    return pdfFields.show();
  });
})

// $(this).trigger('productInput:added')
var hide_destroyed_instance;
hide_destroyed_instance = function(item) {
  event.preventDefault();
  item.closest('tr').find('.destroy-field').detach().appendTo('.parent-group');
  return item.closest('tr').hide().detach().appendTo('.parent-group');
};

var handle_nested;
handle_nested = function(item) {
  var i, nested, len, nestedFields, regexp, time;
  nestedFields = $(item).data('nested-fields');

  for (i = 0, len = nestedFields.length; i < len; i++) {
    nested = nestedFields[i];
    $(nested['append_to']).parent('div').find('table .empty-table').hide();
    time = new Date().getTime();
    regexp = new RegExp(nested['id'], 'g');
    $(nested['append_to']).find('tbody').append(nested['fields'].replace(regexp, time));
  }
};

var map;
var initMap;
window.initMap = function() {
  map = new google.maps.Map(document.getElementById('map'), {
    center: { lat: -34.397, lng: 150.644 },
    fullscreenControl: false,
    mapTypeControl: false,
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    streetViewControl: false,
    zoom: 8,
  });
};

$(document).on("turbolinks:load", () => {
  if ($('#home.sales, #home.index').length > 0) {
    // $('<div id="req-job-id-screen" class="modal-backdrop fade show"></div>').appendTo(document.body)
    var $itemsToDisable = $('#address_div input, .algolia-places input, #property-subtype-dropdown, select#job_subject_radius');
    $itemsToDisable.attr('disabled', true).addClass('disabled');
 }
});
