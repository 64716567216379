import * as cookie from '../libs/cookie_store';

const stateNameToAbbreviation = function stateNameToAbbreviation(abbreviation) {
  let states = {
    "arizona": "AZ",
    "alabama": "AL",
    "alaska": "AK",
    "arkansas": "AR",
    "california": "CA",
    "colorado": "CO",
    "connecticut": "CT",
    "district of columbia": "DC",
    "delaware": "DE",
    "florida": "FL",
    "georgia": "GA",
    "hawaii": "HI",
    "idaho": "ID",
    "illinois": "IL",
    "indiana": "IN",
    "iowa": "IA",
    "kansas": "KS",
    "kentucky": "KY",
    "louisiana": "LA",
    "maine": "ME",
    "maryland": "MD",
    "massachusetts": "MA",
    "michigan": "MI",
    "minnesota": "MN",
    "mississippi": "MS",
    "missouri": "MO",
    "montana": "MT",
    "nebraska": "NE",
    "nevada": "NV",
    "new hampshire": "NH",
    "new jersey": "NJ",
    "new mexico": "NM",
    "new york": "NY",
    "north carolina": "NC",
    "north dakota": "ND",
    "ohio": "OH",
    "oklahoma": "OK",
    "oregon": "OR",
    "pennsylvania": "PA",
    "rhode island": "RI",
    "south carolina": "SC",
    "south dakota": "SD",
    "tennessee": "TN",
    "texas": "TX",
    "utah": "UT",
    "vermont": "VT",
    "virginia": "VA",
    "washington": "WA",
    "west virginia": "WV",
    "wisconsin": "WI",
    "wyoming": "WY",
    "american samoa": "AS",
    "guam": "GU",
    "northern mariana islands": "MP",
    "puerto rico": "PR",
    "us virgin islands": "VI",
    "us minor outlying islands": "UM"
  }

  let a = abbreviation.trim().replace(/[^\w ]/g, "").toLowerCase(); //Trim, remove all non-word characters with the exception of spaces, and convert to lowercase
  if(states[a] !== null) {
    return states[a];
  }

  return null;
}

// const formattedDate = function(date) {
//   return new Date(date).toLocaleDateString();
// };

const formattedDate = function(date) {
  var parsedDate = Date.parse(date);
  var output = '';

  if (isNaN(date) && !isNaN(parsedDate)) {
    output = new Date(date).toLocaleDateString();
  }

  return output;
};

const formattedYear = function(date) {
  // var d = new Date(date).getFullYear();
  // var outputDate ='';

  // if (typeof date !== "undefined" && date !== null && date !== '') {
  //   outputDate = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  // }

  // return outputDate;
  return new Date(date).getFullYear();
};

const uncheckComp = function(compId) {
  processCompCheck(compId, false);
};

const checkComp = function(compId) {
  processCompCheck(compId, true);
};

const processCompCheck = function(compId, checkValue) {
  var item = $('.hit-list input[type=checkbox][value="' + compId + '"]');
  var compType = $('#job_comp_type').val();

  $(item).prop('checked', checkValue);
  // $(item).trigger('cookie:update');
  cookie.updateCookie(compType, compId, checkValue);
};

const mergeIds = function(tempA, tempB) {
  if (isBlank(tempA) && isBlank(tempB)){
    return '';
  } else if (isBlank(tempA)){
    return tempB;
  } else if (isBlank(tempB)) {
    return tempA;
  } else {
    return tempA + ',' + tempB;
  }
};

function isBlank(str) {
  return (!str || /^\s*$/.test(str) || str == 'NaN');
}

export { stateNameToAbbreviation, formattedDate, formattedYear, uncheckComp, checkComp, mergeIds };
