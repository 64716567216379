import * as cart from '../libs/cart';

const Cookies = require('js-cookie')

const updateCookie = function(compType, itemId, isChecked) {
  var currentCompType, cookieName, cookieSet, mySet;

  currentCompType = $('#job_comp_type').val();
  cookieName = 'selections-' + compType;
  cookieSet = populateSetFromCookie(cookieName);

  mySet = processSetChange(cookieSet, itemId, isChecked, compType);
  saveCookie(mySet, compType);
  updateHiddenFields();
};

const populateSetFromCookie = function (cookieName) {
  let tmpSet = new Set();
  var cookieVal = Cookies.get(cookieName);

  if (typeof cookieVal !== "undefined" && cookieVal !== null) {
    var cookieArray = convertStringToArray(cookieVal);

    cookieArray.forEach((element) => {
      tmpSet.add(element);
    })
  }
  return tmpSet;
};

const populateSetFromChecked = function () {
  let tmpSet = new Set();
  var checkedArray = $('.hit-checkbox:checked');

  if (typeof checkedArray !== "undefined" && checkedArray !== null) {
    checkedArray.each(function(i) {
      tmpSet.add($(this).attr('id'));
    })
  }
  return tmpSet;
};

const populateCookieFromHidden = function (hiddenField, compType) {
  let tmpSet = new Set();
  var hiddenArray = hiddenField.split(',');
  var idToChange;

  if (compType == 'Rent') {
    idToChange = 'job_survey_preprocessed_ids_'
  } else {
    idToChange = 'job_sale_event_preprocessed_ids_'
  }

  if (typeof hiddenArray !== "undefined" && hiddenArray !== null) {
    $(hiddenArray).each(function(i,e) {
      tmpSet.add(idToChange + e);
    })
  }
  saveCookie(tmpSet, compType);
};

const processSetChange = function (tmpSet, compId, isChecked, compType) {
  var idToChange;

  if (compType == 'Rent') {
    idToChange = 'job_survey_preprocessed_ids_' + compId
  } else {
    idToChange = 'job_sale_event_preprocessed_ids_' + compId
  }

  if (isChecked == true) {
    tmpSet.add(idToChange);
  } else {
    tmpSet.delete(idToChange);
  }

  return tmpSet;
};

const saveCookie = function(tempSet, compType) {
  var domain = window.location.hostname;
  var cookieName = 'selections-' + compType;
  var cookieValue = Array.from(tempSet);

  Cookies.set(cookieName, cookieValue, {
    domain: domain,
    expires: 14
  });
};

const loadChecksFromSessions = function() {
  var compType = $('#job_comp_type').val();
  var cookieName = 'selections-' + compType;
  var cookieVal = Cookies.get(cookieName);

  if (typeof cookieVal !== "undefined" && cookieVal !== null) {
    var cookieArray = convertStringToArray(cookieVal);

    cookieArray.forEach((element) => {
      var cookieArrayItem = '#' + element;

      if (element !== "") {
        $(cookieArrayItem).attr('checked', true);
      }
    })

    updateHiddenFields();
  }
};

const loadHiddenInputsFromCookie = function(compType) {
  var cookieName = 'selections-' + compType;
  var cookieVal = Cookies.get(cookieName);

  if (typeof cookieVal !== "undefined" && cookieVal !== null) {
    var cookieArray = cart.removeBlanks(pluckNumOnly(cookieVal));

    if (compType == 'Rent') {
      $("#job_survey_ids").val(cookieArray);
    } else {
      $("#job_sale_event_ids").val(cookieArray);
    }
  }
};

const updateHiddenFields = function() {
  loadHiddenInputsFromCookie('Rent');
  loadHiddenInputsFromCookie('Sales');
};

const addCompsFromCookie = function() {
  var compType = $('#job_comp_type').val();
  var cookieVal = readCookie(compType)
  var $tmpCompTypeDiv, alreadyExist;

  if ( cookieVal !== '[]') {
    var cookieArray = pluckNumOnly(cookieVal)

    if (compType == 'Rent') {
      $tmpCompTypeDiv = $('#modal-survey-comps');
    } else {
      $tmpCompTypeDiv = $('#modal-sale-comps');
    }

    cookieArray.forEach((element) => {
      alreadyExist = $tmpCompTypeDiv.find("div[data-comp-id$='" + element + "']").length > 0
      if (element.toString() != 'NaN' && alreadyExist != true) {
        cart.addComp(element, compType);
      }
    })
  }
};

const pluckIds = function(itemArray) {
  var output = [];
  $.each( itemArray, function(i,e){ output.push( e.id )} );
  return output;
};

const pluckNumOnly = function(itemArray) {
  var output = [];
  var input = convertStringToArray(itemArray)
  $.each(input, function(i,e){
    output.push(parseInt(e.split('_').slice(-1)));
  });
  return output;
};

const convertStringToArray = function(item) {
  // ensure not undefined
  if (Boolean(item)) {
    return item.replace('[','').replace(']','').replaceAll('"','').split(',');
  }
};

$(document).on("turbolinks:load", () => {
  if ($('#jobs.show').length > 0) {
    var url = new URL(window.location.href);
    var clear_cookies = url.searchParams.get("cc");

    if (clear_cookies == 'true') {
      wipeCookie();
    }
  }
});

$(document).on('cookie:update', function(e) {
  // ERORR: resave cookie is an issue
  resaveCookie();
  // loadHiddenInputsFromCookie('Rent');
  // loadHiddenInputsFromCookie('Sales');
});

const resaveCookie = function() {
  var compType = $('#job_comp_type').val();
  var checkedSet = populateSetFromChecked();
  saveCookie(checkedSet, compType);
};

const wipeCookie = function() {
  var domain = window.location.hostname;

  Cookies.remove('selections-Rent', { domain: domain })
  Cookies.remove('selections-Sales', { domain: domain })
};

const union = function(setA, setB) {
  let _union = new Set(setA)

  for (let elem of setB) {
    _union.add(elem)
  }
  return _union
};

const readCookie = function(name) {
  var cookieName = 'selections-' + name;
  return Cookies.get(cookieName);
}

export { loadChecksFromSessions, updateCookie, convertStringToArray, readCookie, addCompsFromCookie, pluckIds, pluckNumOnly, populateCookieFromHidden };
