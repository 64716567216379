import * as cookie from '../libs/cookie_store';
import * as helpers from '../libs/helpers';
import * as cart from '../libs/cart';

$(document).on("turbolinks:load", () => {
  var region, county, msa;
  region = $('#property_region_id, #survey_property_attributes_region_id, #sale_event_property_attributes_region_id');
  county = $('#property_county_id');
  msa = $('#property_msa_id');

  region.prop("disabled", true);
  if (county.hasClass('county-disabled')) {
    county.prop("disabled", true);
  }

  if (msa.hasClass('msa-disabled')) {
    msa.prop("disabled", true);
  }

  var stateId;
  $('#property_state_id, #survey_property_attributes_state_id').change(function() {
    stateId = $('#property_state_id option, #survey_property_attributes_state_id option').filter(':selected').val();
    $.ajax({
      type: 'GET',
      url: '/states/' + stateId + '/get_region',
      dataType: 'json',
      success: function(data) {
        region.val(data.id).trigger("change");
      }
    });
    region.prop("disabled", false);
    county.prop("disabled", false);
    msa.prop("disabled", false);

    county.empty();
    msa.empty();

    $.ajax({
      type: 'GET',
      url: '/states/' + stateId + '/msas',
      dataType: 'json',
      success: function(data) {
        data.map(function(option) {
          populate_options(msa, option)
        });
        prepend_blank(msa, 'Select MSA');
      }
    });

    $.ajax({
      type: 'GET',
      url: '/states/' + stateId + '/counties',
      dataType: 'json',
      success: function(data) {
        data.map(function(option) {
          populate_options(county, option)
        });
        prepend_blank(county, 'Select County');
      }
    });
  });

  var job_submit_btn = $('#job-submit');
  job_submit_btn.prop("disabled", true).addClass('disabled');
})

var prepend_blank;
prepend_blank = function(item, placeholderText) {
  var $option = $('<option>');

  $option
    .val('')
    .text(placeholderText);
  item.prepend($option);

  item.val("").trigger("change");
};

var populate_options;
populate_options = function(item, dataOption) {
  var $option = $('<option>');

  $option
    .val(dataOption.id)
    .text(dataOption.name);
  item.append($option);
};

$(document).on('change', "#unit-mix-table select[id$='care_types_survey_id']", function(e) {
  var $this, careTypeId, $tablerow, unitTypeSelect;
  $this = $(this);
  careTypeId = $(this).children("option:selected").data('care-type-id');
  $tablerow = $this.closest('tr');
  unitTypeSelect = $tablerow.find("select[id$='unit_type_id']");

  if(careTypeId != '') {
    unitTypeSelect.prop("disabled", false);
    unitTypeSelect.empty();

    $.ajax({
      type: 'GET',
      url: '/care_types/' + careTypeId + '/unit-types',
      dataType: 'json',
      success: function(data) {
        data.map(function(option) {
          populate_options(unitTypeSelect, option)
        });
        prepend_blank(unitTypeSelect, 'Select Type');
      }
    });
  } else {
    unitTypeSelect.prop("disabled", true);
  }

  var ctsContent = $tablerow.data('care-types-content');
  var resultvalue = ctsContent.find( ({ key }) => key === $this.val() ).value;

  var previousNum = $tablerow.find('.form-control').first().attr('name');
  previousNum = previousNum.match(/\[(\d*)\]/)[1];

  $tablerow.find('.form-control, .destroy-field').each(function(i) {
    $(this).attr('name', $(this).attr('name').replace("[" + previousNum + "]", "[" + resultvalue +"]"));
    $(this).attr('id', $(this).attr('id').replace("_" + previousNum + "_", "_" + resultvalue + "_"));
  });
});

$(document).on('click', "#sale_event_has_lease_data", function(e) {
  var $this = $(this);
  var show_lease_rows = $this.prop("checked");
  var lease_rows = $("#transaction-sens-table .lease-data-true");

  if(show_lease_rows == true) {
    lease_rows.show();
  } else {
    lease_rows.hide();
  }
});

$(document).on('click', "#inc-exc", function(e) {
  var $this = $(this);
  var show_pt_only = $this.prop("checked");
  var pt_any = $("#subtype-any");
  var pt_only = $("#subtype-only");

  uncheck_all_subtypes();

  if(show_pt_only == true) {
    pt_only.show();
    pt_any.hide();

  } else {
    pt_any.show();
    pt_only.hide();
  }
});

var uncheck_all_subtypes;
uncheck_all_subtypes = function() {
  var $checkboxes = $('#property-subtypes-exclusive .facet_item.refined, #property-subtypes .facet_item.refined');

  $checkboxes.each(function(i) {
    $(this).click();
  });
};

$(document).on('click', "#check-job-number", function(e) {
  e.preventDefault();
  var extra_rents, extra_sales;
  var number = $('#job_number').val();
  var job_submit_btn = $('#job-submit');
  var comp_type = $('#job_comp_type').val();

  if (comp_type == 'Rent') {
    extra_rents = cookie.pluckNumOnly(cookie.readCookie('Rent'));
  } else {
    extra_sales = cookie.pluckNumOnly(cookie.readCookie('Sales'));
  }

  $.ajax({
    type: 'GET',
    url: '/jobs/number/' + number + '?comp_type=' + comp_type,
    dataType: 'json',
    success: function(data) {
      if (data['persisted'] == 'true') {
        job_submit_btn.prop("value", 'Save Job')
        fetchJobResults(number, comp_type, extra_rents, extra_sales);
      } else {
        job_submit_btn.prop('value', 'Create Job');
        cart.handleNewJob();
      }

      job_submit_btn.prop("disabled", false).removeClass('disabled');
      $('#address_div input').prop("disabled", false).removeClass('disabled');
      $('#comps-cart-toggle').prop("disabled", false).removeClass('disabled');
      $('#job_subject_address').prop("disabled", false).removeClass('disabled');
      $("#req-job-id-screen").remove();
    },
    error: function(e) {
      console.log(e.message);
    }
  });
});

const fetchJobResults = function(num, comp_type, extra_rents, extra_sales) {
  var token = '73e342a4bbf06358040756b48961f1a7';
  var $modal = $('#compModal');

  $.ajax({
    type: 'GET',
    url: '/api/jobs/' + num + '?extra_rents=' + extra_rents + '&extra_sales=' +extra_sales + '&include_add_line_2=true&include_total_rev_units=true',
    dataType: 'json',
    beforeSend: function (xhr) {
      xhr.setRequestHeader ("Authorization", "Token " + token);
    },
    success: function(data) {
      addCompsMap(data, comp_type);
      cart.addCompsToModal(data, $modal);
    },
    error: function(e) {
      console.log(e.message);
    }
  });
};

const addCompsMap = function(data, comp_type) {
  var itemsToCheck, hiddenField, selectionArray, rentIds, saleIds, apiSaleIds, cookieSaleIds, apiRentIds, cookieRentIds, tmpSalesArray, tmpRentArray, nonCurrentCompType;

  if (comp_type == 'Rent') {
    itemsToCheck = data.surveys;
    hiddenField = $('#job_sale_event_ids');
    nonCurrentCompType = 'Sales';
  } else {
    itemsToCheck = data.sale_events;
    hiddenField = $('#job_survey_ids');
    nonCurrentCompType = 'Rent';
  }

  iterateToCheck(itemsToCheck);

  apiSaleIds = selectionArray = cookie.pluckIds(data.sale_events);
  cookieSaleIds = cookie.pluckNumOnly(cookie.readCookie('Sales'));
  saleIds = helpers.mergeIds(apiSaleIds, cookieSaleIds);
  tmpSalesArray = saleIds.toString().split(',');
  saleIds = cart.removeDuplicates(tmpSalesArray);
  $('#job_sale_event_ids').val(saleIds);

  apiRentIds = cookie.pluckIds(data.surveys);
  cookieRentIds = cookie.pluckNumOnly(cookie.readCookie('Rent'));
  rentIds = helpers.mergeIds(apiRentIds, cookieRentIds);
  tmpRentArray = rentIds.toString().split(',');
  rentIds = cart.removeDuplicates(tmpRentArray);
  $('#job_survey_ids').val(rentIds);

  cookie.populateCookieFromHidden(hiddenField.val(), nonCurrentCompType);
};

const iterateToCheck = function(itemArray) {
  $.each(itemArray, function(i,e){
    helpers.checkComp(e.id);
  });
};
