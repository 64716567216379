import * as cookie from '../libs/cookie_store';
import * as cart from '../libs/cart';

const algoliasearch = require('algoliasearch/lite');
const instantsearch = require('instantsearch.js').default;
const Calendar = require('baremetrics-calendar')
const moment = require('moment')

import { searchBox, hits, pagination, hitsPerPage, configure, currentRefinements, refinementList, rangeSlider, rangeInput, menuSelect, clearRefinements } from 'instantsearch.js/es/widgets';
import { connectConfigure, connectHits, connectRange, connectSearchBox } from 'instantsearch.js/es/connectors';

$(document).on("turbolinks:load", () => {
  if ($('#home.sales').length > 0) {
    var subjectLatLng, rails_env, user_division_code, subject, subjectAddress, subjectRadius;

    rails_env = $('body').data('rails-env')
    user_division_code = $('body').data('user-division-code')
    subjectLatLng = $('#subject-property address').data('subject-lat-lng');
    subject = $('#job-data-element');
    subjectAddress = subject.data('subject-address');
    subjectRadius = subject.data('subject-radius');

    const searchClient = algoliasearch(
      '1JE198VM9W',
      '1b90248ff295562237f2b1ccf47a01b1'
    );

    const search = instantsearch({
      indexName: 'property_sales_' + user_division_code + '_'+ rails_env,
      searchClient,
      routing: true,
    });

    var noResultsTemplate = '<div class="text-center">No results found matching <strong>{{query}}</strong>.</div>';

    var table_header = '<table class="table table-striped table-bordered table-hover"><thead><tr><th>Select</th><th>Edit</th><th>Name</th><th>Address</th><th>County</th><th>Region</th><th>Date</th><th>Type</th><th>Price</th><th>Rev Units</th><th>GBA</th><th>Year Built</th><th>Price/Rev. Unit</th><th>Cap Rate</th><th>Occupancy</th><th>NOI/Rev. Unit</th><th>EGIM</th><th>Expense Ratio</th><th>Subtypes</th></tr></thead><tbody>'

    var markerTemplate = '<i id="marker-{{objectID}}" data-object-id="{{objectID}}" class="fas fa-map-marker-alt {{marker_color_class}}"></i>';

    var facetTemplateCheckbox = '<li class="facet_item {{#isRefined}}refined{{/isRefined}}", \'{{#isRefined}}true{{/isRefined}}\');"><a class="facet-link toggleRefine" data-facet="{{ label.methods }}" data-value="{{ label }}" href="#"><i class="fas fa{{#isRefined}}-check{{/isRefined}}-square" aria-hidden="true"></i>{{ label }}</a><small class="facet_count text-muted float-right">({{ count }})</small></li>'

    const renderHits = (renderOptions, isFirstRender) => {
      const { hits, widgetParams } = renderOptions;

      widgetParams.container.innerHTML = `
        ${table_header}
          ${hits
            .map(
              item =>
                render_table_hit(item)
            )
            .join('')}
          </tbody>
        </table>
      `;
    };

    const customHits = connectHits(renderHits);

    var raw_egim_max = 700;
    var raw_cap_rate_max = 1400;
    var raw_total_rev_units_max = 300;
    var raw_sale_price_max = 100000000;
    var raw_expense_ratio_max = 9000;
    var raw_price_per_rev_unit_max = 500000;
    var raw_noi_per_rev_unit_max = 40000;

    var start_date = new Date();
    var end_date = new Date();
    Date(end_date.setMonth(end_date.getMonth()+1));

    const datePicker = connectRange(
      (options, isFirstRendering) => {
        if (!isFirstRendering) return;

        const { refine } = options;

        new Calendar({
          element: $('#event-date-range'),
          callback: function() {
            const start = new Date(this.start_date).getTime();
            const end = new Date(this.end_date).getTime();
            refine([start, end]);
          },
          start_date: start_date,
          end_date: end_date,
          format: {
            input: 'M/D/YYYY', // Format for the input fields
            jump_month: 'MMMM', // Format for the month switcher
            jump_year: 'YYYY' // Format for the year switcher
          },
          presets: [
            {
              label: 'Last year',
              start: moment().subtract(1, 'year').startOf('year'),
              end: moment().subtract(1, 'year').endOf('year')
            },{
              label: 'Last 2 years',
              start: moment().subtract(2, 'year').startOf('year'),
              end: moment().subtract(1, 'year').endOf('year')
            },{
              label: 'Last 3 years',
              start: moment().subtract(3, 'year').startOf('year'),
              end: moment().subtract(1, 'year').endOf('year')
            }
          ]
        });
      }
    );

    search.addWidgets([
      searchBox({
        container: '#address_div',
        showSubmit: false,
        placeholder: 'Search Sales...',
        cssClasses: {
          input: 'form-control',
          // submit: 'btn btn-outline-secondary'
        },
      }),

      customHits({
        container: document.querySelector('#all-hits'),
      }),

      refinementList({
        attribute: 'property_subtypes_inclusive',
        container: '#property-subtypes',
        operator: 'and',
        templates: {
          item: facetTemplateCheckbox
        },
        cssClasses: {
          list: 'list-unstyled'
        },
        sortBy: ["name:asc"],
        transformItems: function(items) {
          return items.map(function(item) {
            item.label = item.label.split('-')[1]
            return item;
          });
        },
      }),

      refinementList({
        attribute: 'property_subtypes_exclusive',
        container: '#property-subtypes-exclusive',
        operator: 'or',
        templates: {
          item: facetTemplateCheckbox
        },
        cssClasses: {
          list: 'list-unstyled'
        },
        sortBy: ["name:asc"],
        transformItems: function(items) {
          return items.map(function(item) {
            item.label = item.label.split('-')[1]
            return item;
          });
        },
      }),

      refinementList({
        attribute: 'type',
        container: '#ownership-interest',
        operator: 'or',
        templates: {
          item: facetTemplateCheckbox
        },
        cssClasses: {
          list: 'list-unstyled'
        },
      }),

      refinementList({
        attribute: 'region',
        container: '#region',
        operator: 'or',
        templates: {
          item: facetTemplateCheckbox
        },
        cssClasses: {
          list: 'list-unstyled'
        },
      }),

      refinementList({
        attribute: 'state',
        container: '#state',
        operator: 'or',
        searchable: true,
        templates: {
          item: facetTemplateCheckbox
        },
        cssClasses: {
          list: 'list-unstyled',
          searchableInput: 'form-control',
          searchableForm: 'input-group',
          searchableSubmit: 'btn btn-outline-secondary',
        },
      }),

      refinementList({
        attribute: 'county',
        container: '#county',
        operator: 'or',
        searchable: true,
        templates: {
          item: facetTemplateCheckbox
        },
        cssClasses: {
          list: 'list-unstyled',
          searchableInput: 'form-control',
          searchableForm: 'input-group',
          searchableSubmit: 'btn btn-outline-secondary',
        },
      }),

      rangeSlider({
        attribute: 'raw_sale_price',
        container: '#sale-price-range',
        // max: raw_sale_price_max,
        tooltips: {
          format: function(rawValue) {
            if (rawValue == raw_sale_price_max) {
              return '$' + (rawValue/1000000).toLocaleString() + 'M+';
            } else {
              return '$' + (rawValue/1000000).toLocaleString() + 'M';
            }
          }
        },
        cssClasses: {
          root: 'range-input',
        },
      }),

      rangeSlider({
        attribute: 'raw_total_rev_units',
        container: '#rev-units-range',
        cssClasses: {
          root: 'range-input',
        },
        // max: raw_total_rev_units_max,
        tooltips: {
          format: function(rawValue) {
            if (rawValue == raw_total_rev_units_max) {
              return rawValue + "+";
            } else {
              return rawValue;
            }
          }
        },
      }),

      rangeSlider({
        attribute: 'raw_year_open',
        container: '#year-built-range',
        cssClasses: {
          root: 'range-input',
        },
      }),

      rangeSlider({
        attribute: 'raw_occupancy_at_sale',
        container: '#occupancy-at-sale-range',
        tooltips: {
          format: function(rawValue) {
            return Math.round(rawValue).toLocaleString() + '%';
          }
        },
        cssClasses: {
          root: 'range-input',
        },
      }),

      rangeSlider({
        attribute: 'raw_cap_rate',
        container: '#cap-rate-range',
        cssClasses: {
          root: 'range-input',
        },
        // max: raw_cap_rate_max,
        precision: 2,
        tooltips: {
          format: function(rawValue) {
            if (rawValue == raw_cap_rate_max) {
              return raw_cap_rate_max / 100 + "+";
            } else {
              return rawValue / 100;
            }
          }
        },
      }),

      rangeSlider({
        attribute: 'raw_egim',
        container: '#egim-range',
        cssClasses: {
          root: 'range-input',
        },
        precision: 2,
        // max: raw_egim_max,
        precision: 2,
        tooltips: {
          format: function(rawValue) {
            if (rawValue == raw_egim_max) {
              return (rawValue / 100) + "+";
            } else {
              return parseFloat(rawValue / 100).toFixed(2);
            }
          }
        },
      }),

      rangeSlider({
        attribute: 'raw_noi_per_rev_unit',
        container: '#noi-per-rev-unit-range',
        cssClasses: {
          root: 'range-input',
        },
        // max: raw_noi_per_rev_unit_max,
        tooltips: {
          format: function(rawValue) {
            if (rawValue == raw_noi_per_rev_unit_max) {
              return '$' + (rawValue).toLocaleString() + '+';
            } else {
              return '$' + (rawValue).toLocaleString();
            }
          }
        },
      }),

      rangeSlider({
        attribute: 'raw_price_per_rev_unit',
        container: '#price-per-rev-unit-range',
        cssClasses: {
          root: 'range-input',
        },
        // max: raw_price_per_rev_unit_max,
        tooltips: {
          format: function(rawValue) {
            if (rawValue == raw_price_per_rev_unit_max) {
              return '$' + (rawValue/1000).toLocaleString() + 'K+';
            } else {
              return '$' + (rawValue/1000).toLocaleString() + 'K';
            }
          }
        },
      }),

      rangeSlider({
        attribute: 'raw_expense_ratio',
        container: '#expense-ratio-range',
        cssClasses: {
          root: 'range-input',
        },
        // max: raw_expense_ratio_max,
        tooltips: {
          format: function(rawValue) {
            if (rawValue == raw_expense_ratio_max) {
              return rawValue / 100 + "+";
            } else {
              return rawValue / 100;
            }
          }
        },
      }),

      datePicker({
        attribute: 'event_date_timestamp',
        container: '#event-date-range'
      }),

      // pagination({
      //   container: '#pagination-wrap',
      //   cssClasses: {
      //     list: 'pagination',
      //     active: 'active',
      //     pageItem: 'page-item',
      //     link: 'page-link',
      //   },
      //   labels: {
      //     previous: '<i class="fas fa-angle-double-left" aria-hidden="true"></i> Prev',
      //     next: 'Next <i class="fas fa-angle-double-right" aria-hidden="true"></i>',
      //   },
      //   showFirstLast: false,
      //   autoHideContainer: true,
      // }),
      configure({
        hitsPerPage: 1000,
      }),
    ]);

    search.start();

    search.on('render', () => {
      cookie.loadChecksFromSessions();
    });
  }
});

var render_table_hit;
render_table_hit = function(item) {
  var hit_output = ''
  hit_output += '<tr id="hit-' + item.objectID + '" data-object-id="' + item.objectID + '" data-img-url="' + item.images + '" class="sidebar-hit-item"><td><input type="checkbox" value="' + item.last_sale_id + '" name="job[sale_event_preprocessed_ids][]" id="job_sale_event_preprocessed_ids_' + item.last_sale_id + '" class="hit-checkbox"></td><td><a href="/sale_events/' + item.last_sale_id + '/edit" class="marker-edit-survey-link" target="_blank"><i class="far fa-edit"></i></a></td><td class="comp-hit-name">' + item.name + '</td><td class="comp-hit-address"><span class="address-line-1">' + item.address + '</span> <span class="address-line-2">' + item.address_line_2 + '</span></td><td>' + item.county + '</td><td>' + item.region + '</td><td class="comp-hit-date">' + item.event_date + '</td><td class="col-ownership-type">' + item.type + '</td><td class="comp-hit-price">' + item.sale_price + '</td><td class="comp-hit-rev-units">' + item.total_rev_units + '</td><td>' + item.gba + '</td><td class="comp-hit-year-built">' + item.year_open + '</td><td>' + item.price_per_rev_unit + '</td><td>' + item.cap_rate + '</td><td class="comp-hit-occupancy">' + item.occupancy_at_sale + '</td><td>' + item.noi_per_rev_unit + '</td><td>' + item.egim + '</td><td>' + item.expense_ratio + '</td><td class="comp-hit-subtypes">' + item.property_subtypes + '</td></tr>'
  return hit_output;
};

$(document).on('click', '#filter-toggle', function(event) {
  event.preventDefault();
  var container = $('#search-table-container');
  var filterBtn = $('#filter-toggle');

  container.toggleClass('smallWidth');
  filterBtn.find('i').toggleClass('fa-arrow-left').toggleClass('fa-arrow-right')
});

$(document).on('click', '.hit-checkbox', function(event) {
  var compType = $('#job_comp_type').val();

  cookie.updateCookie(compType, this.value, this.checked);
  cart.addOrRemoveComp(this.value, this.checked, compType);
});

// $(document).on('click', '#comps-cart-toggle', function(event) {
//   event.preventDefault();
//   var container = $('#search-table-container');
//   var cartBtn = $('#comps-cart-toggle');

//   container.toggleClass('small-width-cart-open').toggleClass('small-width-cart-closed');
//   // cartBtn.find('i').toggleClass('fa-arrow-left').toggleClass('fa-arrow-right')
// });
