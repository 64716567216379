import * as cookie from '../libs/cookie_store';
import * as helpers from '../libs/helpers';

const noImageUrl = '/no-image-thumb.png';

const addCompsToModal = function(data, modal) {
  var surveyDiv, saleDiv, finalRentArray, finalSaleArray;

  surveyDiv = $('#modal-survey-comps');
  saleDiv = $('#modal-sale-comps');
  finalRentArray = mergeArrays(data.surveys, data.extra_rent_comps);
  finalRentArray = cleanArray(finalRentArray);
  finalSaleArray = mergeArrays(data.sale_events, data.extra_sale_comps);
  finalSaleArray = cleanArray(finalSaleArray);

  $('#modal-job-number').html(data.number);
  renderComps(finalRentArray, 'Rent', surveyDiv);
  renderComps(finalSaleArray, 'Sales', saleDiv);
  modal.modal('show');
};

const renderComps = function(data, compType, div) {
  var compHeader = buildCompHeaderString(compType)
  div.html(compHeader + `${data.map(item => renderCompModalHit(item, compType)).join('')}` + '</div>');
};

const buildCompHeaderString = function(theType) {
  return '<h3>' + theType + '</h3><div class="row row-cols-md-2">'
};

const renderCompModalHit = function(item, compType) {
  var hit_output = ''
  hit_output =`<div class="col mb-4">
    <div class="card h-100" data-comp-id="${item.id}" data-comp-type="${compType}">
      <div class="row-comp-wrap row no-gutters">
        <div class="col-md-2">
          <img src="${item.property.images}" alt="..." class="img-fluid side-img">
        </div>
        <div class="col-md-10 comp-modal-content-col">
          <div class="row">
            <div class="col-md-7 comp-modal-middle-col">
              <p class="card-title">
                <strong>${item.property.name}</strong></br>
                ${item.property.address}</br>
                ${item.property.address_line_2}</br>
                <a href="#" class="comp-modal-remove">Remove</a>
              </p>
            </div>
            <div class="col-md-5">
              <ul class="list-unstyled comp-modal-list">
                ${renderCompTypeList(item, compType)}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>`
  return hit_output;
};

const renderCompTypeList = function(item, compType) {
  var hitListOutput = ''
  if (compType == 'Rent') {
    hitListOutput = `
    <li>Subtypes: ${item.property.property_subtypes}</li>
    <li>Occupancy: ${item.property.occupancy_stabilized}</li>
    <li>Date: ${helpers.formattedDate(item.date_surveyed)}</li>
    <li>Year Built: ${helpers.formattedYear(item.property.date_open)}</li>`
  } else {
    hitListOutput = `
    <li>Date: ${helpers.formattedDate(item.event_date)}</li>
    <li>Price: ${item.sale_price}</li>
    <li>Rev Units: ${item.total_rev_units}</li>
    <li>Year Built: ${helpers.formattedYear(item.property.date_open)}</li>`
  }
  return hitListOutput;
};

const getImageUrl = function(url) {
  if (url == "undefined" || url == null) {
    url = noImageUrl;
  }

  return url;
};

const addComp = function(compId, compType) {
  var theComp, $compTypeDiv, theObject, $c;
  var pageCompType = $('#job_comp_type').val();

  if (pageCompType == compType) {
    helpers.checkComp(compId);
  }

  theComp = $('.hit-list input[type=checkbox][value="' + compId + '"]');

  if (compType == 'Rent') {
    $c = $(theComp.closest('li').find('.sidebar-hit-item'));
    $compTypeDiv = $('#modal-survey-comps');
  } else {
    $c = $(theComp.closest('tr'));
    $compTypeDiv = $('#modal-sale-comps');
  }

  var comp_name = $c.find('.comp-hit-name').text();
  var comp_address = $c.find('.address-line-1').text();
  var comp_address_line_2 = $c.find('.address-line-2').text();
  var subtypes = $c.find('.comp-hit-subtypes').text();
  var occupancy = $c.find('.comp-hit-occupancy').text();
  var date_surveyed = reverseDate($c.find('.comp-hit-date').text());
  // var date_surveyed = $c.find('.comp-hit-date').text();
  var year_built = $c.find('.comp-hit-year-built').text();
  var sale_price = $c.find('.comp-hit-price').text();
  var total_rev_units = $c.find('.comp-hit-rev-units').text();
  var image = $c.data('img-url');

  if (image == "") {
    image = noImageUrl;
  }

  theObject = {
    "id": compId,
    "date_surveyed": date_surveyed,
    "event_date": date_surveyed,
    "sale_price": sale_price,
    "total_rev_units": total_rev_units,
    property: {
      "name": comp_name,
      "address": comp_address,
      "address_line_2": comp_address_line_2,
      "property_subtypes": subtypes,
      "occupancy_stabilized": occupancy,
      "date_open": year_built,
      "images": image,
    }
  };

  $compTypeDiv.find('.row-cols-md-2').append(renderCompModalHit(theObject, compType));
};

const addOrRemoveComp = function(compId, isChecked, compType) {
  if (isChecked == true) {
    addComp(compId, compType);
  } else {
    removeComp(compId, compType);
  }
}

const removeComp = function(compId, compType) {
  var pageCompType = $('#job_comp_type').val();

  removeCompFromModal(compId, compType);

  if (compType == pageCompType) {
    helpers.uncheckComp(compId);
  } else {
    removeFromHiddenFields(compId, compType);
  }
};

const removeCompFromModal = function(compId, compType) {
  var compToHide = $('.card[data-comp-id="' +  compId + '"][data-comp-type="' + compType + '"]').parent();
  compToHide.hide();
};

const removeFromHiddenFields = function(compId, compType) {
  cookie.updateCookie(compType, compId, false);
};

$(document).on('click', ".comp-modal-remove", function(e) {
  e.preventDefault();
  var theComp = $(this).closest('.card');
  var compId = theComp.data('comp-id');
  var compType = theComp.data('comp-type');

  removeComp(compId, compType);
});

const buildCompHeader = function(theDiv, theType) {
  var compHeader = buildCompHeaderString(theType)
  theDiv.html(compHeader + '</div>');
};

const buildNewJobModalHeaders = function() {
  if ($('#modal-survey-comps h3').length == 0) {
    var tmpDiv = $('#modal-survey-comps');
    buildCompHeader(tmpDiv, 'Rent');
  }

  if ($('#modal-sale-comps h3').length == 0) {
    var tmpDiv = $('#modal-sale-comps');
    buildCompHeader(tmpDiv, 'Sales');
  }
};

const handleNewJob = function() {
  buildNewJobModalHeaders();
  cookie.addCompsFromCookie();
};

const reverseDate = function(date) {
  return date.split('\/').reverse().join('-');
};

const removeUndefined = function(ary) {
  return ary.filter(item => item !== undefined);
};

const removeDuplicatesById = function(ary) {
  var result = [];
  var map = new Map();
  for (var item of ary) {
    if(!map.has(item.id)){
      map.set(item.id, true);
      result.push(item);
    }
  }
  return result;
};

const removeDuplicates = function(ary) {
  var result = [];

  ary.forEach((c) => {
    if (!result.includes(c)) {
      result.push(c);
    }
  });

  return result;
};

const removeBlanks = function(ary) {
  return ary.filter(i => i);
};

const cleanArray = function(ary) {
  var tmp = removeUndefined(ary);
  return removeDuplicatesById(tmp);
};

const mergeArrays = function(ary1, ary2) {
  return ary1.concat(ary2);
};

export { addCompsToModal, addComp, addOrRemoveComp, removeBlanks, getImageUrl, handleNewJob, removeDuplicates };
