import * as cookie from '../libs/cookie_store';

const algoliasearch = require('algoliasearch/lite');
const instantsearch = require('instantsearch.js').default;

// import { geoSearch, searchBox, hits, pagination, hitsPerPage, places, configure, currentRefinements, refinementList } from 'instantsearch.js/es/widgets';
import { geoSearch, searchBox, hits, pagination, hitsPerPage, configure, currentRefinements, refinementList } from 'instantsearch.js/es/widgets';
import { connectConfigure, connectRange } from 'instantsearch.js/es/connectors';
import injectScript from 'scriptjs';

$(document).on("turbolinks:load", () => {
  if ($('#home.index').length > 0) {
  // if ($('#home.index, #jobs.edit').length > 0) {
    var subjectLatLng, rails_env;
    rails_env = $('body').data('rails-env')

    subjectLatLng = $('#subject-property address').data('subject-lat-lng');

    var subject, subjectAddress, subjectRadius;

    subject = $('#job-data-element');

    subjectAddress = subject.data('subject-address');
    subjectRadius = subject.data('subject-radius');

    const myItems = [
      { label: '1 Mile', value: milesToMeters(1) },
      { label: '2 Miles', value: milesToMeters(2) },
      { label: '3 Miles', value: milesToMeters(3) },
      { label: '4 Miles', value: milesToMeters(4) },
      { label: '5 Miles', value: milesToMeters(5) },
      { label: '6 Miles', value: milesToMeters(6) },
      { label: '7 Miles', value: milesToMeters(7) },
      { label: '8 Miles', value: milesToMeters(8) },
      { label: '9 Miles', value: milesToMeters(9) },
      { label: '10 Miles', value: milesToMeters(10) },
      { label: '12 Miles', value: milesToMeters(12) },
      { label: '15 Miles', value: milesToMeters(15) },
      { label: '20 Miles', value: milesToMeters(20) },
      { label: '25 Miles', value: milesToMeters(25) },
      { label: '30 Miles', value: milesToMeters(30) },
      { label: 'All', value: 'all' },
    ]

    injectScript(
      // ['https://cdn.jsdelivr.net/npm/places.js@^1.17.0','https://maps.googleapis.com/maps/api/js?v=quarterly&key=AIzaSyA7x1A33WhruFyi-VhrqGU8Lu_5XpNzA9c'],
      'https://maps.googleapis.com/maps/api/js?v=quarterly&key=AIzaSyA7x1A33WhruFyi-VhrqGU8Lu_5XpNzA9c',
      () => {
        const searchClient = algoliasearch(
          '1JE198VM9W',
          '1b90248ff295562237f2b1ccf47a01b1'
        );

        const search = instantsearch({
          indexName: 'properties_' + rails_env,
          searchClient,
          // routing: true,
          // searchFunction(helper) {
            // const page = helper.getPage();
            // helper.setQueryParameter('aroundLatLng', '41.8852827, -87.6215123')
            // helper.setQueryParameter('getRankingInfo', true)
            //       .setPage(page)
            //       .search();
            // helper.search(subjectAddress);
          // },
          // urlSync: {
          //   threshold: 300,
          //   getHistoryState: function() {
          //     return {
          //       turbolinks: true
          //     };
          //   }
          // }
        });


        //////////////////////
        // Create the render function
        const renderConfigure = (renderOptions, isFirstRender) => {
          const { items, refine, widgetParams } = renderOptions;

          if (isFirstRender) {
            const select = document.createElement('select');

            $(select).addClass('form-control select2')
                     .attr('name', 'job[subject_radius]')
                     .attr('id', 'job_subject_radius');

            select.addEventListener('change', event => {
              if(event.target.value === 'all') {
                refine({
                  aroundRadius: widgetParams.searchParameters.aroundRadius = 'all',
                });
              } else {
                refine({
                  aroundRadius: widgetParams.searchParameters.aroundRadius = event.target.value,
                });
              }
            });
            widgetParams.container.appendChild(select);
          }

          const select = widgetParams.container.querySelector('select');
          select.innerHTML = `
            ${myItems
              .map(
                item => `
                  <option
                    value="${item.value}"
                    ${item.value === widgetParams.searchParameters.aroundRadius ? 'selected' : ''}
                  >
                    ${item.label}
                  </option>
                `
              )
              .join('')}
          `;
        };

        // Create the custom widget
        const customConfigure = connectConfigure(
          renderConfigure,
          () => {}
        );
        ////////////////////////

        // const makeRangeWidget = connectRange(
        //   (options, isFirstRendering) => {
        //     if (!isFirstRendering) return;

        //     const { refine } = options;
        //     var dateBegin;
        //     var formattedBeginDate;
        //     var dateEnd;
        //     var formattedEndDate;

        //     $("input[type='date']").change(function() {
        //        dateBegin = $('#date1').val();
        //        dateEnd = $('#date2').val();
        //        /* date / 1000 for miliseconds to seconds conversion
        //        *  -/+ 3601 for same day search and -/+ 1 hour for DST */
        //        formattedBeginDate = (new Date(dateBegin)).getTime() / 1000 - 3601;
        //        formattedEndDate = (new Date(dateEnd)).getTime() / 1000 + 3601;
        //        if (isNaN(formattedEndDate)) {
        //            refine([formattedBeginDate]); // get all events from this date
        //        } else if(isNaN(formattedBeginDate)){
        //            refine([0, formattedEndDate]); // get all events until this date
        //        } else {
        //            refine([formattedBeginDate, formattedEndDate]); // get all events between these dates
        //        }
        //    });
        //   }
        // );

        var infowindow = new google.maps.InfoWindow({
          content: ''
        });

        var noResultsTemplate = '<div class="text-center">No results found matching <strong>{{query}}</strong>.</div>';

        // var meterToMilesRatio = 0.000621371;
        // Distance: {{_rankingInfo.geoDistance}}
        var hitTemplate = '<div id="hit-{{objectID}}" data-object-id="{{objectID}}" class="sidebar-hit-item row" data-img-url="{{images}}"><strong class="col-md-12 comp-hit-name">{{name}}</strong><address class="col-md-12"><span class="address-line-1">{{address}}</span> <span class="address-line-2">{{municipality}}, {{state}} {{zip_code}}</span></address><div class="hit-unit-mix col-md-12">{{{unit_mix_html}}} <span class="hidden comp-hit-year-built">{{date_open}}</span><span class="hidden comp-hit-subtypes">{{property_subtypes}}</span><span class="hidden comp-hit-occupancy">{{occupancy_stabilized}}</span><span class="hidden comp-hit-date">{{date_surveyed}}</span></div></div><input type="checkbox" value="{{last_survey_id}}" name="job[survey_preprocessed_ids][]" id="job_survey_preprocessed_ids_{{last_survey_id}}" class="hit-checkbox">'

        var markerTemplate = '<i id="marker-{{objectID}}" data-object-id="{{objectID}}" class="fas fa-map-marker-alt {{marker_color_class}}"></i>';

        var facetTemplateCheckbox = '<li class="facet_item {{#isRefined}}refined{{/isRefined}}" onClick="sendFilterEvent(\'{{ facet }}\', \'{{ label }}\', \'{{#isRefined}}true{{/isRefined}}\');"><a class="facet-link toggleRefine" data-facet="{{ label.methods }}" data-value="{{ label }}" href="#"><i class="fas fa{{#isRefined}}-check{{/isRefined}}-square" aria-hidden="true"></i>{{ label }}</a><small class="facet_count text-muted float-right">({{ count }})</small></li>'

        // https://community.algolia.com/places/documentation.html
        search.addWidgets([
          // places({
          //   container: '#job_subject_address',
          //   placesReference: window.places,
          //   countries: ['us'],
          //   templates: {
          //     footer: ''
          //   },
          // }),

          searchBox({
            container: '#job_subject_address',
            showSubmit: false,
            placeholder: 'Type Address...',
            cssClasses: {
              input: 'form-control',
            },
          }),

          hits({
            container: '#hits',
            templates: {
              empty: noResultsTemplate,
              item: hitTemplate,
            },
          }),

          refinementList({
            attribute: 'property_subtypes',
            container: '#property-subtypes',
            operator: 'and',
            templates: {
              item: facetTemplateCheckbox
            },
          }),

          geoSearch({
            container: '#maps',
            googleReference: window.google,
            enableRefineControl: false,
            enableRefine: false,
            enableRefineOnMapMove: false,
            mapOptions: {
              zoomControlOptions:  {
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
              },
            },
            customHTMLMarker: {
              createOptions(item) {
                return {
                  title: item.name,
                  content: '',
                  anchor: {
                    x: 0,
                    y: -20,
                  },
                };
              },
              events: {
                click({ event, item, marker, map }) {
                  infowindow.setContent(create_marker_info_html(item));
                  infowindow.open(map, marker);
                  handle_marker_click(marker)
                },
              },
            },
            templates: {
              HTMLMarker: markerTemplate,
            },
          }),
          customConfigure({
            container: document.querySelector('#configure'),
            searchParameters: {
              // similarQuery: subjectAddress,
              // aroundRadius: subjectRadius,
              aroundRadius: 'all',
            },
          }),
          configure({
            hitsPerPage: 200,
          }),
        ]);
        search.start();

        search.on('render', () => {
          cookie.loadChecksFromSessions();
        });
      }
    );
  }
});

var create_marker_info_html;
var markerInfoContent = '';
create_marker_info_html = function(item) {
  markerInfoContent = '';

  if (item.images != null) {
    markerInfoContent += '<img src="' + item.images + '">'
  }
  markerInfoContent += '<address><strong>' + item.name + '</strong><br>' + item.address + '<br>' + item.municipality + ', ' + item.state + ' ' + item.zip_code + '</address>' + '<strong>Property Subtypes: </strong>' + item.property_subtypes + '<br><strong>Occupancy Stabilized: </strong>' + item.occupancy_stabilized + '<br><strong>Date Surveyed: </strong>' + item.date_surveyed + '<br><strong>Year Built: </strong>' + item.year_open + '<br><br>' + item.care_levels_units + '<a href="/surveys/' + item.last_survey_id + '/edit" class="marker-edit-survey-link" target="_blank">Edit Survey <i class="far fa-external-link-alt"></i></a>'

  return markerInfoContent;
};

var milesToMeters;
milesToMeters = function(item) {
  return (item * 1609).toString();
};

$(document).on('click', '#hits .sidebar-hit-item', function(event) {
  event.preventDefault();
  var marker = $('#maps #marker-' + $(this).data('object-id'));
  return marker.click();
});

var handle_marker_click;
handle_marker_click = function(marker) {
  $('#hits .sidebar-hit-item').removeClass('active');
  var markersHit = $('#hits #hit-' + marker.__id);
  $(markersHit).addClass('active');

  if( markersHit.length ) {
    var scrollDest = markersHit.offset().top - 110
    $('#searchbox-wrap').animate({
        scrollTop: $('#searchbox-wrap').scrollTop() + scrollDest
    }, 500);
  }
};
